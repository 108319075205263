import {
  IReqObservationSatuSehat,
  IResObservationSatuSehat,
} from 'src/@types/observation-satu-sehat';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/observation';

const observationSatuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    postObservationSatuSehat: build.mutation<IResObservationSatuSehat, IReqObservationSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'POST',
        data: {
          soapId: data.soapId,
          vitalSignId: data.soapVitalSignId,
        },
      }),
    }),
    updateObservationSatuSehat: build.mutation<IResObservationSatuSehat, IReqObservationSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}/${data.ihsObservationId}`,
        method: 'POST',
        data: {
          soapId: data.soapId,
          vitalSignId: data.soapVitalSignId,
        },
      }),
    }),
  }),
});

export const { usePostObservationSatuSehatMutation, useUpdateObservationSatuSehatMutation } =
  observationSatuSehatApi;
