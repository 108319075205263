import { getProviderId } from 'src/utils/local-storage';
import { IReqConditionSatuSehat, IResConditionSatuSehat } from 'src/@types/condition-satu-sehat';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/condition';

const conditionSatuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    postConditionSatuSehat: build.mutation<IResConditionSatuSehat, IReqConditionSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'POST',
        data,
      }),
    }),
    updateConditionSatuSehat: build.mutation<IResConditionSatuSehat, IReqConditionSatuSehat>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}/${data.ihsConditionId}`,
        method: 'POST',
        data: {
          soapId: data.soapId,
          soapAssessmentId: data.soapAssessmentId,
        },
      }),
    }),
  }),
});

export const { usePostConditionSatuSehatMutation, useUpdateConditionSatuSehatMutation } =
  conditionSatuSehatApi;
