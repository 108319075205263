import { IPagination, IReqGlobalRsql, ILabelValue } from 'src/@types/base';
import { normalizeParams } from 'src/utils/convertFilterString';
import { IIcd10 } from 'src/@types/icd10';

import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/icd10s';

const icd10Api = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    // get /api/kemedis/icd10s
    getIcd10LabelValue: build.query<ILabelValue[], Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      transformResponse: (res: IPagination<IIcd10>) =>
        res.content.map((item) => ({
          label: `${item.icd10Code} - ${item.icdName}`,
          value: item.id,
          description: item.description,
        })),
    }),
  }),
});

export const { useGetIcd10LabelValueQuery } = icd10Api;
