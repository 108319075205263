import {
  IReqIHS,
  IReqIHSCreateNewPatientSatuSehat,
  IReqIHSPostEncounter,
  IReqSearchIHS,
  IResCreateNewPatientSatuSehat,
  IResIHS,
} from 'src/@types/ihs';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/patient';

const ihsApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    searchIHSApi: build.query<IResIHS, IReqSearchIHS>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}/search`,
        method: 'GET',
        params: {
          ...data,
        },
      }),
    }),
    postIHSApi: build.mutation<IResIHS, IReqIHS>({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'POST',
        data,
      }),
    }),
    createNewPatientSatuSehat: build.mutation<
      IResCreateNewPatientSatuSehat,
      IReqIHSCreateNewPatientSatuSehat
    >({
      query: (data) => ({
        url: `${prefix}/${getProviderId()}`,
        method: 'POST',
        data,
      }),
    }),
    createEncounterSatuSehat: build.mutation<string, IReqIHSPostEncounter>({
      query: (data) => ({
        url: `${prefix}/encounter/save`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useSearchIHSApiQuery,
  useLazySearchIHSApiQuery,
  usePostIHSApiMutation,
  useCreateNewPatientSatuSehatMutation,
  useCreateEncounterSatuSehatMutation,
} = ihsApi;
